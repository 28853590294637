import React, { useEffect, useRef, useMemo } from 'react'
import Flickity from 'react-flickity-component'
import { StyledCarouselContainer, StyledFlickityComponent } from './styledCardDesignCarousel'
import { useMediaQuery, useTheme } from '@material-ui/core'
import { FlickityRefObject } from '../../types/FlickityRefObject'

const DEFAULT_CAROUSEL_HEIGHT = {
  mobile: 540,
  desktop: 680,
}

export type CardDesignCarouselProps = Omit<React.ComponentProps<typeof Flickity>, 'flickityRef'> & {
  //
}

export const CardDesignCarousel: React.FC<CardDesignCarouselProps> = ({ children, ...props }) => {
  const flickityRef = useRef(null) as FlickityRefObject
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const isTablet = useMediaQuery(theme.breakpoints.down('sm'))
  const totalItems = React.Children.toArray(children).length
  const carouselHeight = isMobile ? DEFAULT_CAROUSEL_HEIGHT.mobile : DEFAULT_CAROUSEL_HEIGHT.desktop

  if (totalItems === 0) {
    return null
  }

  const groupCells = useMemo(() => {
    return isMobile ? 1 : (isTablet ? 2 : 3)
  }, [isMobile, isTablet])

  const offsetItems = useMemo(() => {
    return totalItems >= groupCells ? groupCells : totalItems
  }, [groupCells, totalItems])

  useEffect(() => {
    if (flickityRef.current && typeof flickityRef.current.flkty?.options === 'object') {
      flickityRef.current.flkty.options.groupCells = groupCells
    }
    
    setTimeout(() => {
      if (flickityRef.current && flickityRef.current.flkty) {
        flickityRef.current.flkty.select(1)
      }

      setTimeout(() => {
        if (flickityRef.current && flickityRef.current.flkty) {
          flickityRef.current.flkty.select(0)
          flickityRef.current.flkty.options.groupCells = groupCells
        }
      }, 200)
    }, 200)
  }, [flickityRef, groupCells])

  return (
    <StyledCarouselContainer>
      <StyledFlickityComponent {...props}
        ref={flickityRef}
        $itemOffset={offsetItems}
        $height={carouselHeight}
        $hasNavigation={totalItems > groupCells}
        options={{
          cellAlign: 'left',
          adaptiveHeight: true,
          freeScroll: true,
          wrapAround: true,
        }}>
        {children}
      </StyledFlickityComponent>
    </StyledCarouselContainer>
  )
}
